import { graphql, useStaticQuery } from "gatsby";
import React,{FunctionComponent, useCallback, useState} from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const Events:FunctionComponent = ()=> {

    const eventsQuery = useStaticQuery(
        graphql`
        query EventsQuery {
            allEventsJson {
                edges {
                    node {
                        gallery{
                            src
                            width
                            height
                        }
                        
                    }
                }
            }
        }
    `);
 
    const photos = eventsQuery.allEventsJson.edges.map(item=>{return item.node});
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    //const openLightbox = useCallback((event, { photo, index }) => {
    const openLightbox = useCallback((event, { photo, index }) => {    
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);
    
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

    return(<>
            <Gallery photos={photos} onClick={openLightbox}/>
            <ModalGateway>
                {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                    <Carousel
                    currentIndex={currentImage}
                    views={photos.map(x => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title
                    }))}
                    />
                </Modal>
                ) : null}
            </ModalGateway>
    </>)
}

export default Events;